// helpers
import { getUrl, ROUTES } from 'shared/api/urls';
import { ApiHelper } from 'shared/helpers/api';

// constants
import { UPS_PARAMS } from 'shared/constants';
import { API_METHODS, METHODS } from 'shared/constants/api';

export const getUPSOrderCount = () => {
  const searchParams = new URLSearchParams({
    attributes: UPS_PARAMS.INSTALL_OFFER,
    catalog_tag_filter: UPS_PARAMS.NF,
  });
  const url = getUrl(ROUTES.FETCH_UPS_DATA) + '?' + searchParams.toString();
  return ApiHelper(url, {
    method: API_METHODS[METHODS.GET],
  }).then((data) => {
    if (data.status !== 200) {
      return Promise.reject({ message: data.status });
    }
    return data.json();
  });
};
