export const TEXTS = {
  CONTINUE_TO_CHECKOUT: 'Proceed to Buy',
  VIEW_DETAILS: 'View Details',
  CONTINUE_TO_PAYMENT: 'Proceed to Pay',
  REWARDS_TEXT_SAVED: 'You saved ',
  REWARDS_TEXT_PURCHASE: ' on this purchase',
};

export const SCREEN_TYPES = {
  CART: 'cart',
  ADDRESS: 'address',
};

export const FREE_GIFT_SESSION_KEY = 'freeGiftsNudgeShown';