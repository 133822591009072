// constants
import { CHECKOUT_V3_ADDRESS_URI, NEW_PAYMENT_STACK } from 'shared/constants/uri';
import { FREE_GIFT_SESSION_KEY } from 'shared/components/CartCTA/constants';

// helpers
import { getDeviceType } from 'shared/helpers/app';

// defs
import { CartItem } from 'shared/types/cart';

interface FreeGiftNudgeParams {
  eligibleCount: number;
  claimedCount: number;
}

export const getAllImageFromItems = (items: CartItem[]) => {
  return items.map((item) => item.imageUrl);
};

export const getNewCheckoutURI = () => {
  const isMobile = getDeviceType() === 'MSITE';

  if (isMobile) {
    return NEW_PAYMENT_STACK;
  }

  return CHECKOUT_V3_ADDRESS_URI;
};

export const setCheckOutTiming = () => {
  window.sessionStorage.setItem('checkoutRedirectionTimestamp', String(Date.now()));
};

export const isFreeGiftNudgeRequired = ({ eligibleCount, claimedCount }: FreeGiftNudgeParams): boolean => {
  const isNudgeShown = sessionStorage.getItem(FREE_GIFT_SESSION_KEY);
  if (!isNudgeShown && eligibleCount > claimedCount) {
    return true;
  }
  return false;
};

export const clearFreeGiftsSessionKey = () => {
  if(sessionStorage.getItem(FREE_GIFT_SESSION_KEY)) {
    sessionStorage.removeItem(FREE_GIFT_SESSION_KEY);
  }
};