// libs
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// apis
import { getLoggedInUser } from 'shared/api/auth';
import { getMenuContent } from 'shared/api/mega-menu';
import { getOfferText } from 'shared/api/offer';
import { getUPSOrderCount } from 'shared/api/UPSOrderCount';

// Helpers
import logger from 'shared/logger';

// constants
import MemoizeAPIWithTTL from 'shared/helpers/memoizeAPIWithTTL';
import { API_CACHE_TTL } from 'shared/constants';

// redux store
import { ActionTypes } from './actions';

const memoizedGetMenuDataV2 = MemoizeAPIWithTTL({
  APIFunction: getMenuContent,
  ttl: API_CACHE_TTL,
  cacheHash: 'MEGA_MENU',
});
const memoFetchOffer = MemoizeAPIWithTTL({
  APIFunction: getOfferText,
  ttl: API_CACHE_TTL,
  cacheHash: 'OFFER_TEXT',
});

export const fetchMenuDataV2 = () => {
  return (dispatch: any) => {
    dispatch({ type: ActionTypes.FETCH_MENU_PROGRESS });
    return memoizedGetMenuDataV2()
      .then((data) => {
        if (isEmpty(data)) {
          //@ts-ignore
          logger.error({ ERROR: 'MANDATORY_RESPONSE_EMPTY', METHOD_NAME: 'getMenuContent' });
        }

        dispatch({
          type: ActionTypes.FETCH_MENU_SUCCESS,
          payload: data,
        });
      })
      .catch(() => {
        dispatch({ type: ActionTypes.FETCH_MENU_FAILED });
      });
  };
};

export const fetchUser = (): any => {
  return (dispatch: any) => {
    dispatch({ type: ActionTypes.FETCH_USER_PROGRESS });
    return getLoggedInUser()
      .then((data) => {
        const { form_Key: formKey, cartCount, customerData, base_location } = data.response;
        dispatch({
          type: ActionTypes.FETCH_USER_SUCCESS,
          payload: { formKey, cartCount, customerData, base_location },
        });

        return data.response;
      })
      .catch(() => {
        dispatch({ type: ActionTypes.FETCH_MENU_FAILED });
        dispatch({ type: ActionTypes.FETCH_USER_FAILED });
        return {};
      });
  };
};

export const fetchOffer = (): any => {
  return (dispatch: any) => {
    dispatch({ type: ActionTypes.FETCH_HEADER_OFFER });
    return memoFetchOffer()
      .then((data) => {
        dispatch({
          type: ActionTypes.SET_HEADER_OFFER,
          payload: get(data, 'response.header_top.0', {}),
        });
      })
      .catch(() => {
        dispatch({ type: ActionTypes.FETCH_HEADER_OFFER_FAILED });
      });
  };
};

export const resolveMenuAPI = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: ActionTypes.FETCH_MENU_SUCCESS,
      payload: [],
    });
  };
};

export const fetchUPSData = () => {
  return (dispatch: any) => {
    dispatch({ type: ActionTypes.FETCH_UPS_DATA_PROGRESS });
    return getUPSOrderCount()
      .then((data) => {
        const { result } = data;
        const installOfferCount = get(result, 'install_offer.count', {});

        dispatch({
          type: ActionTypes.SET_UPS_ORDER_COUNT,
          payload: installOfferCount,
        });

        return installOfferCount;
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.SET_UPS_ERROR,
          payload: `UPS_API_FAILURE: ${err}`,
        });

        return {};
      });
  };
};
