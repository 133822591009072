export enum METHODS {
  GET,
  POST,
  PATCH,
  DELETE,
}

export const API_METHODS = {
  [METHODS.GET]: 'GET',
  [METHODS.POST]: 'POST',
  [METHODS.PATCH]: 'PATCH',
  [METHODS.DELETE]: 'DELETE',
};
